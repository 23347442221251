<template>
  <div class="home">
    <div class="diy-wg-tmp">
      <div>
        x：
        <input
          type="number"
          placeholder="纵向网格"
          v-model="cell"
          @input="getRows('cell')"
        />
        y：
        <input
          type="number"
          placeholder="横向网格"
          v-model="row"
          @input="getRows('row')"
        />

        <button @click="getAllPaths">获取所有路径</button>
      </div>

      <div>
        <div>图例：</div>
        <div class="wg-items">
          <div v-for="(row, rowIndex) in rows" :key="rowIndex" class="grid-row">
            <div
              v-for="(cell, cellIndex) in row"
              :key="cellIndex"
              class="grid-cell"
              :class="getActive(rowIndex, cellIndex)"
            ></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 所有网格路线start -->

    <div v-if="allPaths.length > 0" style="margin-top:40px">从a到b的路径一共有{{ allPaths.length }}种</div>
    <div class="big-wg-box">
      <div
        style="display: inline-block"
        v-for="(path, index) in allPaths"
        :key="index"
      >
        <div class="wg-items">
          <svg
            :width="(cell - 1) * 50"
            :height="(row - 1) * 50"
            class="grid-svg"
          >
            <path
              :d="getSvgPath(path)"
              stroke="red"
              fill="none"
              stroke-width="2"
            />
          </svg>
          <div v-for="(row, rowIndex) in rows" :key="rowIndex" class="grid-row">
            <div
              v-for="(cell, cellIndex) in row"
              :key="cellIndex"
              class="grid-cell"
              :class="getActive(rowIndex, cellIndex)"
            ></div>
          </div>
        </div>
        <div>第{{ index + 1 }}种</div>
      </div>
    </div>
    <!-- 所有网格路线end -->

    <div>1.画网格，创建两个input框，M,N,在通过计算属性创建一个二维数组，但是对应的M、N要去减1，这样就能保证点位对应。</div>
    <div>
      2.设置起始位置，也是通过计算设置起始点，终点点。
          path() {
            return [
              [this.row - 2, 0],
              [0, this.cell - 2],
            ];
          },
    </div>
    <div>
      3.通过递归的方式，找到所有的路径。（方法是网上查询的，采用回溯法，然后再进行修改）
      <p>
        <img width="500" src="@/assets/1.png" alt="">
      </p>
    </div>
    <div>
      4.通过获取到的路径，在用svg画出路径。
      <p>
        <img width="500" src="@/assets/2.png" alt="">
      </p>
    </div>
    <div>
      5.经过测试，当网格较小的时候，能快速找到所有的路径。网格过大的时候就会卡死。（对算法不熟）<br/>
      2*6 0种路径<br/>
      3*6 16种路径<br/>
      3*7 32种路径<br/>
      3*8 64种路径<br/>
      当4*6的时候就会卡死, 所以设置了y只能小于4
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Home",
  data() {
    return {
      row: 3,
      cell: 6,
      allPaths: [], //纪录找到储存所有的路径
    };
  },
  computed: {
    rows() {
      // 根据输入框初始化一个几成几的二维数组
      return Array.from({ length: this.row - 1 }, () =>
        Array.from({ length: this.cell - 1 }, () => null)
      );
    },
    path() {
      return [
        [this.row - 2, 0],
        [0, this.cell - 2],
      ];
    },
  },
  mounted() {
    this.allPaths = this.countHamiltonianPaths(this.row, this.cell);
  },
  methods: {
    getRows() {
      this.allPaths = [];
    },
    getAllPaths(type) {
      if (this.row == "" || this.cell == "") {
        alert("请输入行数或列数");
        return;
      }
      if (this.cell > 8) {
        alert("x最大只能输入8");
        return;
      }
      if (this.row > 3) {
        alert("y最大只能输入3");
        return;
      }

      if (this.cell < 2) {
        alert("x最小只能输入2列");
        return;
      }
      if (this.row < 2) {
        alert("y最小只能输入2");
        return;
      }

      this.allPaths = this.countHamiltonianPaths(this.row, this.cell);
    },
    getSvgPath(path) {
      let d = "M ";
      path.forEach(([pathY, pathX], index) => {
        // pathX = pathX ? pathX + 1 : pathX;
        // pathY = pathY ? pathY + 1 : pathY;

        const x = pathX * 50;
        const y = pathY * 50;
        if (index === 0) {
          d += `${x} ${y} `;
        } else {
          d += `L ${x} ${y} `;
        }
      });
      return d;
    },
    countHamiltonianPaths(M, N) {
      const totalPoints = M * N;
      const start = [M - 1, 0]; // 最下面一行的第一个点
      const end = [0, N - 1]; // 最上面一行的最后一个点
      let visited = Array.from({ length: M }, () => Array(N).fill(false));
      let paths = []; // 用于存储所有汉密尔顿路径的数组
      function dfs(x, y, path) {
        if (x < 0 || y < 0 || x >= M || y >= N || visited[x][y]) {
          return;
        }

        visited[x][y] = true;
        path.push([x, y]); // 将当前坐标添加到路径中
        if (x === end[0] && y === end[1] && path.length === totalPoints) {
          paths.push([...path]); // 复制当前路径并添加到所有路径中
        } else {
          // 同样的移动逻辑
          if (x > 0) dfs(x - 1, y, path);
          if (x < M - 1) dfs(x + 1, y, path);
          if (y > 0) dfs(x, y - 1, path);
          if (y < N - 1) dfs(x, y + 1, path);
        }

        visited[x][y] = false; // 回溯前取消标记
        path.pop(); // 移除最后一个坐标，以便回溯
      }
      dfs(start[0], start[1], []);
      return paths; 
    },
    getActive(rowIndex, cellIndex) {
      if (
        this.path.some(
          ([r, c], index) => r == rowIndex && c == cellIndex && index == 0
        )
      ) {
        return "dianwei";
      } else if (
        this.path.some(
          ([r, c], index) => r == rowIndex && c == cellIndex && index == 1
        )
      ) {
        return "dianwei2";
      } else {
        return "";
      }
    },
  },
  components: {},
};
</script>
<style lang="less" scoped>
img{
  max-width: 500px;
  width: 100%;
}
.diy-wg-tmp {
}
* {
  box-sizing: border-box;
}
.big-wg-box {
  .wg-items {
    display: inline-flex;
    margin-bottom: 10px;
    margin-right: 10px;
  }
}
.wg-items {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  position: relative;
  border: 1px solid #000;
  .grid-svg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
}
.grid-row {
  display: flex;
}
.grid-row:last-child .grid-cell {
  border-bottom: none;
}

.grid-cell:last-child {
  border-right: none;
}
.grid-cell {
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  width: 50px;
  height: 50px;
  position: relative;
  &.dianwei::after {
    position: absolute;
    content: "a";
    left: -15px;
    bottom: -15px;
  }

  &.dianwei::before {
    position: absolute;
    content: "";
    left: -2.5px;
    bottom: -2.5px;
    width: 5px;
    height: 5px;
    background: red;
    border-radius: 50%;
  }

  &.dianwei2::after {
    position: absolute;
    content: "b";
    right: -15px;
    top: -15px;
  }
  &.dianwei2::before {
    position: absolute;
    content: "";
    right: -2.5px;
    top: -2.5px;
    width: 5px;
    height: 5px;
    background: red;
    border-radius: 50%;
  }
}
</style>